/** Basic Layout - Header, Footer, Side-menu **/

// ==================
// Library
// ==================
import { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { CacheSwitch } from 'react-router-cache-route'
import loadable from '@loadable/component'
import { BackTop, Layout, message } from 'antd'

// ==================
// Customization
// ==================
// import tools from '../util/tools'
import './BasicLayout.css'

// ==================
// Component
// ==================
import Header from '../components/Header'
import MenuCom from '../components/Menu'
import Footer from '../components/Footer'
import Loading from '../components/Loading'
import ErrorBoundary from '../components/ErrorBoundary'

import BreadTab from '../components/BreadTab' // BreadCrumb navi

// Menu mapping
// import { menu as menuConfig, power as powerConfg } from "../config/menu";

import { RootState, Dispatch } from '../store'
// import { Menu } from '../models/index.type'
import { History as BrowseHistory } from 'history'
import StockEdit from '../pages/Machine/MachineDetail/StockEdit'
import { useCookieState } from 'ahooks'

const { Content } = Layout

// ==================
// Dynamic imports
// ==================
const [
  NotFound,
  NoPower,
  Home,
  // MenuAdmin,
  // PowerAdmin,
  // RoleAdmin,
  // UserAdmin,
  Machine,
  ProductList,
  ProductDetail,
  // Catalog,
  // CatalogDetail,
  FirestoreError
  // CatalogCreate,
  // ProductCreate
  // MachineDetail
  // ProductEdit,
  // MachineCreate
  // ChangeCatalog
] = (
  [
    async () => await import('../pages/ErrorPages/404'),
    async () => await import('../pages/ErrorPages/401'),
    async () => await import('../pages/Home'),
    // async () => await import('../pages/System/MenuAdmin'),
    // () => import(`../pages/System/PowerAdmin`),
    // () => import(`../pages/System/RoleAdmin`),
    // async () => await import('../pages/System/UserAdmin'),
    async () => await import('../pages/Machine'),
    async () => await import('../pages/Product'),
    async () => await import('../pages/Product/ProductDetail'),
    // async () => await import('../pages/Catalog'),
    // async () => await import('../pages/Catalog/CatalogDetail'),
    async () => await import('../pages/ErrorPages/firestoreError')
    // async () => await import('../pages/Catalog/CatalogCreate'),
    // async () => await import('../pages/Product/ProductCreate')
    // async () => await import('../pages/Machine/MachineDetail')
    // async () => await import(`../pages/Product/ProductDetail/ProductEdit`),
    // async () => await import('../pages/Machine/MachineCreate')
    // async () => await import(`../pages/Machine/MachineDetail/ChangeCatalog`),
  ] as const
).map((item) => {
  return loadable(item, {
    fallback: <Loading />
  })
})

const ProductEdit = loadable(async () => await import('../pages/Product/ProductDetail/ProductEdit'), {
  fallback: <Loading />
})

const ProductCreate = loadable(async () => await import('../pages/Product/ProductCreate'), {
  fallback: <Loading />
})

const MachineCreate = loadable(async () => await import('../pages/Machine/MachineCreate'), {
  fallback: <Loading />
})

const MachineDetail = loadable(async () => await import('../pages/Machine/MachineDetail'), {
  fallback: <Loading />
})

const ChangeCatalog = loadable(async () => await import('../pages/Machine/MachineDetail/ChangeCatalog'), {
  fallback: <Loading />
})

const MachineEdit = loadable(async () => await import('../pages/Machine/MachineDetail/MachineEdit'), {
  fallback: <Loading />
})

const PlaylistEdit = loadable(async () => await import('../pages/Machine/MachineDetail/PlaylistEdit'), {
  fallback: <Loading />
})

const Media = loadable(async () => await import('../pages/Media'), {
  fallback: <Loading />
})

const MediaDetail = loadable(async () => await import('../pages/Media/MediaDetail'), {
  fallback: <Loading />
})

const MediaCreate = loadable(async () => await import('../pages/Media/MediaCreate'), {
  fallback: <Loading />
})

const MediaEdit = loadable(async () => await import('../pages/Media/MediaDetail/MediaEdit'), {
  fallback: <Loading />
})

const MediaTools = loadable(async () => await import('../pages/Media/MediaTools'), {
  fallback: <Loading />
})

const DailyTransaction = loadable(async () => await import('../pages/DailyTransaction'), {
  fallback: <Loading />
})

const DailyTransactionDetail = loadable(async () => await import('../pages/DailyTransaction/DailyTransactionDetail'), {
  fallback: <Loading />
})

const MachineTransaction = loadable(async () => await import('../pages/MachineTransaction'), {
  fallback: <Loading />
})

const MachineTransacionDetail = loadable(
  async () => await import('../pages/MachineTransaction/MachineTransacionDetail'),
  {
    fallback: <Loading />
  }
)

// ==================
// Types declaration
// ==================
type Props = ReturnType<typeof mapState> &
ReturnType<typeof mapDispatch> & {
  history: BrowseHistory
  location: Location
}


// ==================
// Component
// ==================
function BasicLayoutCom(props: Props): JSX.Element {
  const [collapsed, setCollapsed] = useCookieState('useCookieStateUpdater', {
    defaultValue: 'FALSE',
  })
  // Logout
  const onLogout = useCallback(() => {
    // TODO Re-enable rule
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    props.onLogout().then(() => {
      message.success('Success').then(
        () => {},
        () => {}
      )
      props.history.push('/user/login')
    })
  }, [props])

  // Handle firestore error
  useEffect(() => {
    if (props.firestore.errors.allIds.length > 0) {
      console.debug(props.firestore.errors)
      props.history.push('/firestoreError')
    }
    // TODO Re-enable rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.firestore, props.project])

  // 切换路由时触发
  const onEnter = useCallback((Component, componentProps) => {
    if (
      typeof componentProps.match.params.project !== 'undefined' &&
      localStorage.getItem('project') !== componentProps.match.params.project
    ) {
      console.debug('Project Swtiched')
      localStorage.setItem('project', componentProps.match.params.project)
      // TODO Re-enable rule
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      props.setProject(componentProps.match.params.project)
    }
    return <Component {...componentProps} />
  }, [])

  return (
    <Layout className='page-basic' hasSider>
      <BackTop />
      <MenuCom data={props.userinfo} collapsed={collapsed === 'TRUE' ? true : false} location={props.location} history={props.history} />
      <Layout style={{ marginLeft: collapsed === 'TRUE' ? 80 : 256 }}>
        <Header
          collapsed={collapsed === 'TRUE' ? true : false}
          {...props.userinfo}
          onToggle={() => setCollapsed((prev) => prev === 'TRUE' ? 'FALSE' : 'TRUE')}
          onLogout={onLogout}
          location={props.location}
          history={props.history}
        />

        <BreadTab
          // menus={props.userinfo.menus}
          location={props.location}
          // history={props.history}
        />
        <Content className='content'>
          <ErrorBoundary location={props.location}>
            <CacheSwitch>
              <Redirect exact from='/' to={'home'} />
              <Redirect exact from='/home' to={`${props.project}`} />
              <Redirect exact from={`/${props.project}`} to={`/${props.project}/home`} />
              <Route exact path='/:project/home' render={(pageProps) => onEnter(Home, pageProps)} />
              <Route exact path='/:project/machine' render={(pageProps) => onEnter(Machine, pageProps)} />
              <Route exact path='/:project/machine/create' render={(pageProps) => onEnter(MachineCreate, pageProps)} />
              <Route exact path='/:project/product/create' render={(pageProps) => onEnter(ProductCreate, pageProps)} />
              <Route exact path='/:project/product/:status' render={(pageProps) => onEnter(ProductList, pageProps)} />
              <Redirect exact from='/:project/product' to='/:project/product/publish' />
              <Route
                exact
                path='/:project/product/:status/:code'
                render={(pageProps) => onEnter(ProductDetail, pageProps)}
              />
              <Route
                path='/:project/product/:status/:code/edit'
                render={(pageProps) => onEnter(ProductEdit, pageProps)}
              />
              <Route exact path='/:project/machine/:docId' render={(pageProps) => onEnter(MachineDetail, pageProps)} />
              <Route
                path='/:project/machine/:docId/catalog'
                render={(pageProps) => onEnter(ChangeCatalog, pageProps)}
              />
              <Route
                path='/:project/machine/:docId/playlist'
                render={(pageProps) => onEnter(PlaylistEdit, pageProps)}
              />
              <Route path='/:project/machine/:docId/edit' render={(pageProps) => onEnter(MachineEdit, pageProps)} />
              <Route path='/:project/machine/:docId/stock' render={(pageProps) => onEnter(StockEdit, pageProps)} />
              <Route exact path='/:project/media-list/create' render={(pageProps) => onEnter(MediaCreate, pageProps)} />
              <Route exact path='/:project/media-list/:status' render={(pageProps) => onEnter(Media, pageProps)} />
              <Redirect exact from='/:project/media-list' to='/:project/media-list/publish' />
              <Route
                exact
                path='/:project/media-list/:status/:id'
                render={(pageProps) => onEnter(MediaDetail, pageProps)}
              />
              <Route
                exact
                path='/:project/media-list/:status/:id/edit'
                render={(pageProps) => onEnter(MediaEdit, pageProps)}
              />
              <Route exact path='/:project/media-tools' render={(pageProps) => onEnter(MediaTools, pageProps)} />
              {/* <Redirect from="/:project/transaction" to={`/:project/transaction/${moment().subtract(30,'days').format('YYYYMMDD')}/${moment().format('YYYYMMDD')}`} exact /> */}
              <Route
                exact
                path='/:project/machine-transaction'
                render={(pageProps) => onEnter(MachineTransaction, pageProps)}
              />
              <Route
                exact
                path='/:project/machine-transaction/:docId'
                render={(pageProps) => onEnter(MachineTransacionDetail, pageProps)}
              />
              <Route
                exact
                path='/:project/daily-transaction'
                render={(pageProps) => onEnter(DailyTransaction, pageProps)}
              />
              <Route
                exact
                path='/:project/daily-transaction/list'
                render={(pageProps) => onEnter(DailyTransactionDetail, pageProps)}
              />
              <Route exact path='/nopower' component={NoPower} />
              <Route exact path='/firestoreError' component={FirestoreError} />
              <Route component={NotFound} />
            </CacheSwitch>
          </ErrorBoundary>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  )
}

const mapState = (state: RootState) =>
  ({
    userinfo: state.app.userinfo,
    project: state.app.project,
    firestore: state.firestore
  } as const)
const mapDispatch = (dispatch: Dispatch) =>
  ({
    onLogout: dispatch.app.onLogout,
    setProject: dispatch.app.setProject
  } as const)

// TODO Re-enable rule and fix circular reference
// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
// @ts-ignore
export default connect(mapState, mapDispatch)(BasicLayoutCom)
